/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import { withAudiDesignSystemLight } from '@oneaudi/narown-utils-ui';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import React, { useContext } from 'react';
import { SecondaryLinksContent } from './components/SecondaryLinksContent/index';
import { ContentContext } from './context';

const Container = withAudiDesignSystemLight(SecondaryLinksContent);

interface WrapperProps {
  configProvider: GfaServiceConfigProviderV1;
}

const FeatureApp: React.FC<WrapperProps> = () => {
  const context = useContext(ContentContext);
  const { content } = context;

  return <Container cardsData={content} />;
};

export default FeatureApp;
