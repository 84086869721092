import { createContext } from 'react';
import { CardsData } from '../types/shared';

type ContentContextState = {
  content: CardsData;
};

export const ContentContext = createContext<ContentContextState>({
  content: {
    cards: [],
    displayIcons: false,
  },
});
